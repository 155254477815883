import axios from "axios";
import * as Helper from "@/helper";

const state = {
  dictionary: {},
  currencyAll: [],
  reports_results: [
    {
      value: "pending",
      title: "Pending",
    },
    {
      value: "success",
      title: "Success",
    },
    {
      value: "failed",
      title: "Failed",
    },
    {
      value: "undeliverable",
      title: "Undeliverable",
    },
  ],
  regions: [],
  loader: {
    region: false,
  },
};

const getters = {
  reports_results: state => state.reports_results,
  dictionary: state => {
    return {
      ...state.dictionary,
      networks: state.dictionary.networks.map(item => {
        return {
          ...item,
          old_name: item.name,
          name: item.locked_name || item.name,
        };
      }),
    };
  },
  prepaidPlans: state =>
    state.dictionary.plans.filter(item => item.type === "prepaid"),
  hybridPlans: state =>
    state.dictionary.plans.filter(item => item.type === "hybrid"),
  postpaidPlans: state =>
    state.dictionary.plans.filter(item => item.type === "postpaid"),
  privatePlans: state =>
    state.dictionary.plans.filter(item => item.type === "private"),
  trialPlans: state =>
    state.dictionary.plans.filter(item => item.type === "trial"),
  regionLoader: state => state.loader.region,
  regions: state => state.regions,
  getCurrencyCode: state => {
    if (!state.currencyAll.length) return {};
    let currency = {};
    state.currencyAll.forEach(item => (currency[item.key] = item.code));

    return currency;
  },
};

const mutations = {
  saveDictionary(state, data) {
    state.dictionary = {
      ...state.dictionary,
      [data.dictionary_name]: data.value,
    };
  },

  setRegions(state, data) {
    state.regions = data;
  },

  addRegion(state, payload) {
    state.regions.push(payload);
  },
  deleteRegion(state, id) {
    state.regions = state.regions.filter(item => item.id !== id);
  },
  setRegionLoader(state, val) {
    state.loader.region = val;
  },
  setCurrencies(state, val) {
    state.currencyAll = val;
  },
  updateNetworkInDictionary(state, payload) {
    state.dictionary = {
      ...state.dictionary,
      networks: state.dictionary.networks.map(item => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      }),
    };
  },
};

const actions = {
  async fetchAllDictionary({ dispatch }) {
    await Promise.allSettled([
      dispatch("fetchProviders"),
      dispatch("fetchCountries"),
      dispatch("fetchNetworks"),
      dispatch("fetchStatuses"),
      dispatch("fetchPlans"),
      dispatch("fetchMoNetworks"),
      dispatch("fetchManagers"),
      dispatch("fetchUserRoles"),
    ]);
  },
  async fetchProviders({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("/providers");
      commit("saveDictionary", { dictionary_name: "providers", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchCountries({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("countries");
      commit("saveDictionary", { dictionary_name: "countries", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchNetworks({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("networks");
      commit("saveDictionary", { dictionary_name: "networks", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchStatuses({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("/reports/statuses");
      commit("saveDictionary", { dictionary_name: "statuses", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchPlans({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("plans");
      commit("saveDictionary", { dictionary_name: "plans", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchMoNetworks({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("/networks/mo");
      commit("saveDictionary", { dictionary_name: "mo_networks", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchManagers({ commit }, { showError = false } = {}) {
    try {
      const {
        data: { data },
      } = await axios.post("/admins/commercial-managers");
      commit("saveDictionary", { dictionary_name: "managers", value: data });
    } catch (e) {
      if (showError)
        Helper.errorMessage(
          e.response?.data?.message || "Error! Something went wrong"
        );
    } finally {
    }
  },
  async fetchRegions({ commit }) {
    try {
      commit("setRegionLoader", true);
      const {
        data: { data },
      } = await axios.post("billing-region");
      commit("setRegions", data);
    } catch (e) {
      Helper.errorMessage(
        e.response?.data?.message || "Error! Something went wrong"
      );
    } finally {
      commit("setRegionLoader", false);
    }
  },
  async fetchUserRoles({ commit }) {
    try {
      const {
        data: { data },
      } = await axios.get("/roles/all");
      commit("saveDictionary", { dictionary_name: "roles", value: data.roles });
    } catch (e) {
      Helper.errorMessage(
        e.response?.data?.message || "Error! Something went wrong"
      );
    }
  },
  async fetchCurrencies({ commit }) {
    try {
      const {
        data: { data },
      } = await axios.get("/profile/currencies");
      commit("setCurrencies", data);
    } catch (e) {
      Helper.errorMessage(
        e.response?.data?.message || "Error! Something went wrong"
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
